// @flow

import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Box } from '@latitude/box';
import { FeaturesSlider } from '@latitude/features-slider';
import { HorizontalRule } from '@latitude/horizontal-rule';
// import { StickyCta } from '@latitude/sticky-cta';
import { Heading3 } from '@latitude/heading';
import { StickyNavigation } from '@latitude/sticky-navigation';
import {
  BREAKPOINT,
  COLOR,
  PADDING
} from '@latitude/core/utils/constants';
import FeatureTiles from '../../components/FeatureTiles/FeatureTiles'
import ImportantInformation from '../../components/ImportantInformation/ImportantInformation';
import Lframe from '../../components/Lframe/Lframe';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import ConfettiFramedContainerKiwibank from '../../components/ConfettiFramedContainer/ConfettiFramedContainerKiwibank';
// import { SOFT_QUOTE_KIWIBANK_URL } from '../../utils/softQuoteUtil';
import { CardGroup, CardGroupItem } from '../../components/CardGroup/CardGroup';
import LoanCardBranded from '../../components/LoanCard/LoanCardBrandedKb';
import Layout from '../../components/layout-kiwibank';
import PageData from '../../data/pages/personal-loan-kiwibank.json';
import MobileAppInstallPrompts from '../../components/MobileAppInstallPrompts/MobileAppInstallPrompts';
import { Table, Row, Cell } from '../../components/TableNew/TableNew';
import CalculatorTile from '../../components/Calculators/CalculatorTile';
import { featuresSliderIE11Fix } from '../../utils/stylesUtils';
import { LOAN_PURPOSE } from '../../utils/constants';
import EstimateRateWidgetSectionKiwibank from '../../components/EstimateRateWidget/EstimateRateWidgetSectionKiwibank';
import useApplyUrl from '../../hooks/useApplyUrl';
import SoftQuoteContext from '../../context/SoftQuoteContext';
import Faq from '../../components/Faq/Faq';
import FaqData from '../../data/json/faq-debt-con.json';
import HowToApplyEligibility from '../../components/HowToApplyEligibility/HowToApplyEligibilityKiwibank';
import FooterKiwibank from '../personal-loans/_footer';

/*
 * Kiwibank Debt Consolidation Loan Page
 */
const DebtConLoanPage = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const { applyUrl, setApplyUrl } = useApplyUrl();

  return (
    <Layout noFooter>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer personal-loan-page">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://kiwibank.latitudefinancial.com/debt-consolidation/"
            />
            <title>
              Debt Consolidation Loan NZ | Latitude for Kiwibank
            </title>
            <meta
              name="description"
              content="Our debt consolidation loan in New Zealand can help you consolidate your debts into one easy to manage payment. Get a quote online in under 2 minutes."
            />
            <meta
              name="keywords"
              content="Personal Loan,Personal Loan NZ,Personal Loans,Apply for a Personal Loan,Debt Consolidation Loans,Kiwibank"
            />
          </Helmet>

          <Lframe debtConLoansKiwibank hasMobileStickyCta />
          <HeroBranded
            title="Debt Consolidation Loan"
            text={
              <>
                Destress your mind by streamlining your debts into one
                easy-to-manage payment.
              </>
            }
            buttonProps={{
              href: '/loan-calculator/',
              children: 'Calculate your repayments',
              trackId: 'hero-calculate-your-repayments',
            }}
            trackEventData={{
              category: 'cta',
              action: 'quote-link'
            }}
          />

          {/* Start: Hero with ribbon / no image */}
          <SoftQuoteContext.Provider value={{ applyUrl, setApplyUrl }}>
            {/* <div className="d-lg-none d-xl-none">
              <StickyCta
                href={applyUrl}
                trackId="sticky-check-your-eligibility"
                text="Check your eligibility"
              />
            </div> */}
            {/* End: Hero with ribbon / no image */}

            <div className="d-none d-lg-block">
              <StickyNavigation
                items={PageData.nav}
                phoneNumber={PageData.content.phoneNumberKiwi}
                isSticky={isNavSticky}
                onStickyNavStateChange={setIsNavSticky}
                // ctaHref={applyUrl}
                // ctaTarget="_self"
                // ctaText="Check your eligibility"
                offsetElem="[data-sticky-navigation-offset]"
                trackId="sticky-nav-check-your-eligibility"
                trackEventData={{
                  category: 'cta',
                  action: 'quote-link',
                  label: 'Check your eligibility',
                  location: 'Sticky Navigation'
                }}
                trackProductId={['PLNZLF-WEB']}
              />
            </div>

            <EstimateRateWidgetSectionKiwibank
              purpose={LOAN_PURPOSE.CONSOLIDATION}
              trackProductId={['PLNZLF-WEB']}
            />
          </SoftQuoteContext.Provider>

          <AnalyticsLocationProvider location="Debt Consolidation Loan">
            <Box.Section
              backgroundColor={COLOR.GREY6}
              id="rates"
            >
              <Heading3
                css={`
                  font-size: 24px;
                  color: ${COLOR.BLACK};
                  text-align: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    font-size: 32px;
                  }
                `}
              >
                Debt Consolidation Loan
              </Heading3>
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-sm-8 col-md-7 col-lg-12">
                  <CardGroup
                    css={`
                      max-width: 1100px;
                      margin: auto;
                    `}
                  >
                    <CardGroupItem col={2}>
                      <LoanCardBranded
                        kiwibank
                        icon="personal-loan"
                        isBranded
                        className="h-100"
                        title="Debt Consolidation Loan"
                        description={
                          <>
                            Streamline your debts into one easy-to-manage payment.
                          </>
                        }
                        rateBoxType="kiwibankPersonal"
                        rateBoxType2="kiwibankHome"
                        rateBoxFullWidth
                        listItems1={[
                          'Loan amounts from $2,000',
                          'Loan terms from 1-7 years',
                          'Fixed rate for the life of the loan',
                          'No early repayment fees',
                          'Establishment fee of $199 applies'
                        ]}
                        // button2={{
                        //   href: SOFT_QUOTE_KIWIBANK_URL,
                        //   trackId: 'personal-loan-check-your-eligibility',
                        //   text: 'Check your eligibility',
                        //   target: '_self',
                        //   trackEventData: {
                        //     category: 'cta',
                        //     action: 'quote-link',
                        //     location: 'Personal Loan'
                        //   }
                        // }}
                      />
                    </CardGroupItem>
                  </CardGroup>
                </div>
              </div>
            </Box.Section>
          </AnalyticsLocationProvider>

          <CalculatorTile kiwibank />

          <FeaturesSlider
            heading="Why debt consolidation"
            id="why-us"
            className="why-choose bg-f8f8f8"
            data={PageData.whyChooseKiwibank}
            css={featuresSliderIE11Fix}
          />
          <HorizontalRule />
          <LoanDetailsSection />

          <HowToApplyEligibility />

          <FeatureTiles
            id="apply"
            heading="Apply for a Personal Loan"
            tiles={PageData.content.applyKiwibank.tiles}
          />

          <Faq data={FaqData} autoHeightOnDesktop />

          <ImportantInformation
            data={require('../../data/pages/personal-loan-kiwibank.json')}
            sectionOneColummClasses="col-10 offset-1"
          />
        </Box>
      </main>
      <FooterKiwibank />
    </Layout>
  );
};

const LoanDetailsSection = () => (
  <ConfettiFramedContainerKiwibank heading="Let’s talk loan details" id="details">
    <Box padding={PADDING.P24}>
      <Table>
        <tbody>
          <Row>
            <Cell>Fixed rate</Cell>
            <Cell>For the life of the loan</Cell>
          </Row>
          <Row>
            <Cell>Loan amounts</Cell>
            <Cell>From $2,000</Cell>
          </Row>
          <Row>
            <Cell>Repayments</Cell>
            <Cell>Weekly, fortnightly, or monthly</Cell>
          </Row>
          <Row>
            <Cell>Loan terms</Cell>
            <Cell>1-7 years</Cell>
          </Row>
          <Row>
            <Cell>No early repayment fees</Cell>
            <Cell>Pay your loan off sooner, at no extra charge!</Cell>
          </Row>
          <Row>
            <Cell>Establishment fee</Cell>
            <Cell>$199</Cell>
          </Row>
          <Row>
            <Cell css="border-bottom: none">Late fee</Cell>
            <Cell css="border-bottom: none">$35 if you don&apos;t make the minimum repayment by your agreed due date</Cell>
          </Row>
        </tbody>
      </Table>
    </Box>
  </ConfettiFramedContainerKiwibank>
);

export default DebtConLoanPage;
